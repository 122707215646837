#Menu {
  position: fixed;
  top: 3.75rem;
  left: 0;
  right: 0;
  border-top: 0.0625rem solid var(--n0);
  overflow: auto;
  bottom: 0;
  background: linear-gradient(to bottom, #5145c5 0, #8160d3 100%);
  z-index: 1000;

  @media (min-width: $width-lg) {
    display: flex !important;
    position: static;
    flex-grow: 1;
    background: none;
    justify-content: space-between;
    border: 0 none;
    align-items: center;
    overflow: visible;
  }

  ul {
    background: none;
    margin: 0;

    li {
      display: block;

      @media (min-width: $width-lg) {
        padding: 0 0.75rem;
        display: inline-block;
      }
    }
    a {
      padding: 0.625rem 1.25rem;
      font-size: 1.125rem;
      color: var(--n0);
      display: block;
      line-height: 1;
      font-weight: $medium;

      @media (min-width: $width-lg) {
        font-size: 1rem;
        line-height: 1.5rem;
        padding: 0.3125rem 0;
      }
      &:hover {
        opacity: 0.5;
      }
    }
    .is-active {
      > a {
        background: var(--n0);
        color: var(--dark-grey);

        &:hover {
          opacity: 1;
        }
        @media (min-width: $width-lg) {
          background: none;
          color: var(--n0);
          border-bottom: 0.0625rem solid var(--n0);
        }
      }
    }
  }
  .top-bar-right {
    @media (min-width: $width-lg) {
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1 auto;
    }
    li {
      @media (min-width: $width-lg) {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
      }
    }
    a {
      @media (min-width: $width-lg) {
        font-size: 0.8125rem;
        line-height: 1.5rem;
        padding: 0;
      }
    }
  }
  .user {
    position: relative;
    width: 100%;

    @media (min-width: $width-lg) {
      background: none;
      margin-left: 1.375rem;
      width: auto;
    }
    > a {
      padding: 0.5rem 1.25rem;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      color: var(--n0);
      width: 100%;

      @media (min-width: $width-lg) {
        background: none;
        padding: 0;
      }

      &:hover,
      &:focus {
        background: var(--n0);
        color: var(--n600);

        .profile-details {
          p {
            color: var(--n600);

            @media (min-width: $width-lg) {
              color: var(--n0);
            }
          }
        }
        i {
          color: var(--n600);

          @media (min-width: $width-lg) {
            color: var(--n0);
          }
        }

        @media (min-width: $width-lg) {
          background: none;
        }
      }
    }
    .avatar {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 0.5rem;

      @media (min-width: $width-lg) {
        order: 0;
        margin-right: 0.8125rem;
      }
      img {
        width: 2rem;
        height: 2rem;
      }
      .p-avatar {
        &.p-avatar-lg {
          width: 2rem;
          height: 2rem;
          font-size: 0.875rem;
          font-weight: $bold;
          color: var(--n500);
        }
      }
    }
    .profile-details {
      flex: 1 auto;
      padding-right: 1.25rem;

      @media (min-width: $width-lg) {
        order: 1;
      }
      @media (min-width: $width-xl) {
        width: 9.625rem;
      }
      p {
        color: var(--n0);
        font-size: 1rem;
        line-height: 1.1875rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;

        strong {
          font-weight: $medium;
        }
        span {
          display: block;
          white-space: nowrap;
          font-size: 0.75rem;
          line-height: 1.0625rem;
          color: var(--b200);
          overflow: hidden;
          text-overflow: ellipsis;

          @media (min-width: $width-xl) {
            width: 7.5rem;
          }
        }
      }
    }
    i {
      color: var(--n0);

      @media (min-width: $width-lg) {
        order: 2;
      }
    }
    .dd-open {
      i {
        &:before {
          transform: rotate(90deg);
        }
      }
    }
    .p-menu-overlay {
      background: none;
      box-shadow: none;
      max-width: 100%;
      width: 100%;
      position: static;

      @media (min-width: $width-lg) {
        width: 17.3125rem;
        max-width: 17.3125rem;
        background: var(--n0);
        box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.16);
        position: absolute;
        right: 0;
        left: auto !important;
        top: 100% !important;
        margin-top: 1.375rem;
      }
      .p-submenu-header {
        padding: 0.75rem 1.5625rem 0.75rem 2.4375rem;
        font-weight: $bold;
        font-size: 0.875rem;
        margin-bottom: 0;
        color: var(--n0);
        margin-top: 0.3125rem;
        background: none;

        @media (min-width: $width-lg) {
          color: var(--n600);
          margin-top: 0;
          padding: 0.75rem 1.5625rem;
        }
      }
      .p-menuitem {
        @media (min-width: $width-lg) {
          display: block;
          padding: 0;
        }
      }
      .p-menuitem-link {
        color: var(--n0);
        font-size: 1.125rem;
        padding: 0.4375rem 1.875rem 0.4375rem 3.3125rem;

        @media (min-width: $width-lg) {
          color: var(--n600);
          padding: 0.4375rem 1.875rem 0.4375rem 2.5rem;
          font-size: 1rem;
        }
        &:hover,
        &:focus,
        &.current {
          opacity: 0.5;
          background: none;

          @media (min-width: $width-lg) {
            opacity: 1;
          }
          .p-menuitem-text {
            @media (min-width: $width-lg) {
              color: var(--b700);
            }
          }
        }
        .p-menuitem-text {
          color: var(--n0);

          @media (min-width: $width-lg) {
            color: var(--n600);
          }
        }
      }
      .p-menuitem-separator {
        margin: 0;
        display: block;

        + .p-menuitem {
          a {
            padding: 0.625rem 1.25rem;
            color: var(--n0);
            font-size: 1.125rem;

            @media (min-width: $width-lg) {
              color: var(--n600);
              padding: 0.5rem 1.5rem;
              font-size: 1rem;
            }

            &:hover,
            &:focus,
            &.current {
              opacity: 0.5;
              background: none;

              @media (min-width: $width-lg) {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}
.menu-open {
  position: fixed;
  z-index: 1101;
  width: 100%;
  top: 0;

  @media (min-width: $width-lg) {
    position: static;
  }
}
