.op {
	&.p-overlaypanel {
		border-radius: 0.5rem;
		background: var(--b600);
		box-shadow: 0rem 0.5rem 1.25rem 0.25rem rgba(0, 0, 0, 0.1);
		width: 100%;
		max-width: 21.3125rem;
		left: 50%;
		transform: translate(-50%, 0) !important;
		z-index: 1 !important;
    top: unset;

		&:before {
			width: 0 !important;
			height: 0 !important;
			border-style: solid !important;
			border-width: 0 0.5rem 0.5rem 0.5rem !important;
			border-color: transparent transparent var(--b600) transparent !important;
			margin: 0;
			left: 50%;
			transform: translateX(-50%);
		}
		&:after {
			display: none;
		}
		.p-overlaypanel-content {
			padding: 1rem;
			text-align: left;

			p {
				font-size: 1rem;
				line-height: 1.5rem;
				color: var(--n0);

				@media (min-width: 48rem) {
					font-size: 0.8125rem;
					line-height: 1.125rem;
				}
			}
			a {
				font-weight: 500;
				color: var(--n0);
				font-size: 1rem;
				line-height: 1.5rem;

				@media (min-width: 48rem) {
					font-size: 0.8125rem;
					line-height: 1.125rem;
				}
				&:hover {
					text-decoration: underline;
				}
			}
			.btn-sd {
				@media (max-width: 47.9375rem) {
					font-size: 1rem;
					height: 2.5rem;
					line-height: 2.375rem;
				}
			}
		}
		&.right {
			left: auto;
			transform: translateY(0) !important;
			width: auto;
			direction: ltr;

			&:before {
				transform: none;
				left: auto;
				right: 0.75rem;
			}
		}
		&.op-project {
			left: auto;
			transform: translateY(0) !important;
			width: auto;
			direction: ltr;
			right: 0.9375rem;
      top: auto;

			&:before {
				transform: none;
				left: auto;
				right: 2.5rem;
			}
		}
		&.op-project-detail {
			left: 50%;
			transform: translateY(0) !important;
			transform: translateX(-50%) !important;
			max-width: 21.3125rem;
      width: 90vw;
			right: unset;
			top: auto;

			@media (min-width: 23.1875rem) {
				left: auto;
				transform: translateX(0) !important;
				width: 21.3125rem;
				direction: ltr;
				right: -0.3125rem;
			}

			&:before {
				display: none;
			}
		}
		&.op-edit-profile {
			left: -1.25rem;
			transform: translateY(0) !important;
			max-width: 21.3125rem;
      width: 90vw;
			right: auto;
      top: auto;

			@media (min-width: 36rem) {
				right: 0;
				left: auto;
				direction: ltr;
			}
			&:before {
				left: 3.375rem;
				right: auto;
				transform: none;

				@media (min-width: 36rem) {
					left: auto;
					right: 2rem;
				}
			}
		}
		&.op-working-days {
			margin-top: 0;
			margin-bottom: 0.625rem;
			left: -0.9375rem;
			transform: translateY(0) !important;
			width: 21.3125rem;
			right: auto;
			top: auto;
			bottom: 100%;

			@media (min-width: 36rem) {
				right: 0;
				left: auto;
				direction: ltr;
			}
			&:before {
				transform: none;
				top: 100%;
				bottom: auto;
				left: 5.25rem;
				border-width: 0.5rem 0.5rem 0 0.5rem !important;
				border-color: var(--b600) transparent transparent transparent !important;

				@media (min-width: 36rem) {
					left: auto;
					right: 4.125rem;
				}
			}
		}
		&.op-habit {
			margin-top: 0;
			margin-bottom: 0.625rem;
			left: -1.875rem;
			transform: translateY(0) !important;
			width: 21.3125rem;
			right: auto;
			top: auto;
			bottom: 100%;

			&:before {
				transform: none;
				top: 100%;
				bottom: auto;
				left: 2.5rem;
				border-width: 0.5rem 0.5rem 0 0.5rem !important;
				border-color: var(--b600) transparent transparent transparent !important;
			}
		}
		&.op-calendar-icon {
			margin-top: 0;
			margin-bottom: 0.625rem;
			left: -1.5625rem;
			transform: translateY(0) !important;
			width: 21.3125rem;
			right: auto;
			top: auto;
			bottom: 100%;

			@media (min-width: 48rem) {
				left: 0;
			}
			&:before {
				display: none;
			}
			p {
				overflow: visible !important;
				white-space: normal !important;
			}
		}
	}
}
