.p-toast {
  &.sd-toast {
    opacity: 1;

    @media (min-width: $width-xs) {
      width: 40.125rem;
    }
  
    &.p-toast-bottom-center {
      @media (max-width: $max-width-xs) {
        left: .9375rem;
        right: .9375rem;
        width: auto;
        transform: none;
      }
    }
  
    .p-toast-message {
      border-width: 0 0 0 .5rem;
      border-radius: .5rem;
  
      &.p-toast-message-success {
        background-color: var(--n0);
        border-color: var(--g300);

        .p-toast-message-icon {
          background: var(--g50);
          color: var(--g300);

          checkicon {
            display: none;
          }

          &:before {
            font-family: "torobravo";
            font-style: normal;
            font-weight: normal;
            speak: none;
            display: inline-block;
            text-decoration: inherit;
            text-align: center;
            content: '\e85e';
          }
        }
      }
      &.p-toast-message-error {
        background-color: var(--n0);
        border-color: var(--o200);

        .p-toast-message-icon {
          background: var(--o50);
          color: var(--o200);

          timescircleicon {
            display: none;
          }

          &:before {
            font-family: "torobravo";
            font-style: normal;
            font-weight: normal;
            speak: none;
            display: inline-block;
            text-decoration: inherit;
            text-align: center;
            content: '\e85c';
          }
        }
      }
      &.p-toast-message-info {
        background-color: var(--n0);
        border-color: var(--b400);

        .p-toast-message-icon {
          background: var(--b10);
          color: var(--b400);

          &:before {
            font-family: "torobravo";
            font-style: normal;
            font-weight: normal;
            speak: none;
            display: inline-block;
            text-decoration: inherit;
            text-align: center;
            content: '\e85d';
          }
        }
      }
      .p-toast-message-content {
        padding: 1.5rem;

        @media (min-width: $width-xs) {
          padding-left: 2rem;
        }
      }
      .p-toast-message-icon {
        width: 2.5rem;
        height: 2.5rem;
        text-align: center;
        line-height: 2.5rem;
        flex: 0 0 auto;
        border-radius: 50%;
        font-size: 1rem;

        @media (min-width: $width-xs) {
          width: 4.5rem;
          height: 4.5rem;
          line-height: 4.5rem;
          font-size: 1.5rem;
        }
      }
      .p-toast-message-text {
        padding-right: 1rem;

        @media (min-width: $width-xs) {
          padding:0 2rem;
        }
        .p-toast-summary {
          color: var(--n500);
          margin-bottom: .5rem;
          font-size: 1rem;

          @media (min-width: $width-xs) {
            font-size: 1.25rem;
          }
        }
        .p-toast-detail {
          margin:0;
          color: var(--n200);
          font-size: .875rem;

          @media (min-width: $width-xs) {
            font-size: 1rem;
          }
        }
      }
      .p-toast-icon-close {
        height: 1.5rem;
        width: 1.5rem;
        flex: 0 0 auto;
        margin-top: -0.625rem;
        margin-right: -0.625rem;
      }
      .p-toast-icon-close-icon {
        color: var(--n60);
        line-height: 1.5rem;
        
        &:before {
          font-size: .75rem;
        }
      }
    }
  }
}