.timebar {
  background: var(--n20);
  height: 2rem;
  margin-top: 2rem;
  position:relative;

  &-block {
    height: 100%;
    position:absolute;
    padding: .5rem .25rem;
  }
  .calendar {
    &-block {
      border: .0625rem solid var(--b100);
      text-align: center;
      padding:0;
      display: flex;
      justify-content: space-around;
      align-items: center;

      img {
        max-width: 1rem;
      }
    }
  }
  .focus-block {
    background: url("/assets/images/art/stripe.png") left center;
    height: 1rem;
    position:absolute;
    top: -1.375rem;
    border: 1px dashed var(--b100);
    border-radius: 100px;
    padding: .125rem .375rem;
  }
  .marker {
    position: absolute;
    height: 4.375rem;
    width: .0625rem;
    position: absolute;
    bottom: -0.5rem;
    z-index: 2;
    margin:0;

    h5 {
      position:relative;
      height: 100%;
      display: block;
      padding-top: 1.375rem;
      
      strong{
        position:absolute;
        left: 50%;
        transform: translateX(-50%);
        top:0;
        font-size: .75rem;
        line-height: .75rem;
      }
      img {
        position:absolute;
        top: 1.0231rem;
        left: 50%;
        transform: translateX(-50%);
        width: .6837rem;
        height: .3906rem;
        max-width: none;
      }
      &:after {
        content:'';
        height: 3rem;
        width: .0625rem;
        background: var(--n500);
        position:absolute;
        bottom:0;
      }
    }
  }
}
.legend {
  display: inline-flex;
  width: 100%;
  text-align: left;
  justify-content: space-between;

  li {
    // width: 14.29%;
    color: var(--n200);
    font-size: .6875rem;
    font-weight: normal;
  }
}
.timer-list {
  .timebar {
    height: auto;
    min-height: 100%;
    margin-top: 0;
    background: none;

    .marker,
    .focus-block,
    .legend {
      display: none;
    }
    &-block {
      padding:0;
    }
  }
  .legend {
    display: none;
  }
}