.focus-box {
	position: relative;
	text-align: left;
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
	max-width: 65.1875rem;
	margin-left: auto;
	margin-right: auto;

	.toggler {
		position: absolute;
		right: 1rem;
		top: 1rem;
		font-size: 0.5rem;
		color: var(--n100);
		cursor: pointer;

		@media (min-width: 48rem) {
			top: 1.5rem;
		}
		i {
			&:before {
				width: auto;
			}
		}
		&.show {
			transform: rotate(180deg);
		}
	}
}
.focus-panel {
	border: 0.0625rem solid var(--n60);
	border-radius: 0.5rem;
	padding: 0.5rem 1.25rem;

	@media (min-width: 48rem) {
		display: flex;
		padding: 1.5rem 0;
	}
	&.cols-2 {
		p-panel {
			@media (min-width: 48rem) {
				flex: 0 0 50%;
			}
		}
	}
	&.cols-3 {
		p-panel {
			@media (min-width: 48rem) {
				flex: 1 0 32%;
			}
			&.best-time {
				@media (min-width: 48rem) {
					flex: 1 0 26.5%;
				}
			}
			&.weekly-insight {
				@media (min-width: 48rem) {
					flex: 1 0 36%;
				}
			}
		}
	}
	.panel-head {
		display: flex;
		justify-content: flex-start;
		align-content: center;

		h4 {
			font-size: 0.8125rem;
			line-height: 1rem;
			font-weight: 400;
			color: var(--n500);
			margin-bottom: 0.25rem;

			span {
				color: var(--n200);
			}
			img {
				height: 1rem;
				vertical-align: middle;
				display: inline-block;
			}
		}
		img {
			height: 3rem;
		}
		p {
			margin: 0;
			font-size: .875rem;
			line-height: 1.125rem;
			color: var(--n200);

			strong {
				font-weight: 400;
				color: var(--n500);
			}
		}
	}
	p-panel {
		@media (min-width: 48rem) {
			padding: 0 1rem;
		}
		@media (min-width: 61.9375rem) {
			padding: 0 1.5rem;
		}
		&:not(:first-child) {
			@media (min-width: 48rem) {
				border-left: 0.0625rem solid var(--n60);
			}
		}
		.p-panel {
			padding: 1rem 0;

			@media (min-width: 48rem) {
				padding: 0;
			}
			.p-panel-header {
				border: 0 none;
				border-radius: 0;
				background: none;
				padding-left: 0;
				padding-right: 0;
				display: block;

				@media (min-width: 48rem) {
					padding: 0;
				}
				.p-panel-icons {
					display: none !important;
				}
			}
			.p-panel-content {
				padding: 0;
				border: 0 none;

				p {
					font-size: 0.8125rem;
					line-height: 1.125rem;
					color: var(--n200);
					margin-bottom: 0.5rem;
				}
				.tip {
					background: var(--b10);
					padding: 1rem;
					border-radius: 0.625rem;

					p {
						margin: 0;
						font-size: 0.6875rem;
						line-height: 1rem;
						color: var(--n200);

						strong {
							color: var(--b400);
						}
					}
				}
				table {
					width: 100%;

					th {
						padding: 0.5rem 0;
						border-top: 0.0625rem solid var(--n40);
						font-size: 0.6875rem;
						line-height: 1rem;
						color: var(--n500);
						font-weight: 400;
					}
					td {
						text-align: right;
						padding: 0.5rem 0;
						border-top: 0.0625rem solid var(--n40);
						font-size: 0.6875rem;
						line-height: 1rem;
						color: var(--n200);
						font-weight: 400;
					}
				}
			}
			.expanded-meeting {
				margin-top: 1.5rem;
				padding-left: 1.375rem;
				position: relative;

				&:first-child {
					margin-top: 0;
				}

				img {
					position: absolute;
					left: 0;
					top: 0;
				}
				p {
					margin: 0;
					font-size: 0.8125rem;
					line-height: 1.1375rem;
					color: var(--n200);

					strong {
						font-weight: 500;
						color: var(--n500);
					}
				}
			}
		}
		+ p-panel {
			.p-panel {
				border-top: 0.0625rem solid var(--n40);

				@media (min-width: 48rem) {
					border-top: 0 none;
				}
			}
		}
	}
	.focus-details {
		&.panel-open {
			.panel-head {
				flex-direction: column;
			}
		}
		&.empty {
			&.panel-open {
				@media (min-width: 48rem) {
					align-self: center;
				}
			}
			.panel-head {
				@media (min-width: 48rem) {
					align-items: center;
				}
				p {
					padding-left: 1rem;
				}
			}
		}
		&:not(.empty) {
			.panel-head {
				margin-left: -0.5rem;
				margin-right: -0.5rem;
				width: auto;

				> * {
					flex: 0 0 50%;
					padding-left: 0.5rem;
					padding-right: 0.5rem;
				}
				.noTrack {
					flex: 1 1 auto;
					display: inline-flex;

					p {
						text-transform: none;
						padding-left: 1rem;

						strong {
							display: block;
						}
					}
					+ .meeting-scheduled {
						flex: 1 1 auto;

						p {
							white-space: nowrap;
							padding: 0;
						}
					}
				}
				.time-spent {
					h4 {
						i {
							display: none;
						}
					}
					p {
						span {
							display: none;
						}
					}
				}
				p {
					text-transform: lowercase;
				}
				i {
					color: var(--b400);
					font-size: 0.5625rem;
					margin-right: 0.25rem;
				}
			}
			.panel-content {
				padding-top: 1.5rem;
			}
			&.panel-open {
				.time-spent {
					flex: 0 0 100%;

					h4 {
						font-size: 1.25rem;
						line-height: 1.25rem;

						i {
							display: inline-block;
							color: var(--o200);
							font-size: 0.5rem;
							vertical-align: top;
							margin-top: 0;
							margin-left: 0.125rem;
						}
					}
					p {
						span {
							display: inline;
						}
					}
				}
				.meeting-scheduled {
					display: none;
				}
			}
		}
	}
	.best-time {
		&.panel-open {
			.panel-head {
				.focus-time {
					flex: 0 0 100%;

					h4 {
						order: 1;
					}
				}
				.task-amount {
					display: none;
				}
				h4 {
					margin: 0;
					font-size: 1.25rem;
					line-height: 1.25rem;
				}
				p {
					text-transform: none;
					color: var(--n500);
					margin-bottom: 0.75rem;
					font-size: 0.8125rem;

					em {
						color: var(--n100);
					}
				}
				i {
					font-size: 1.25rem;
				}
			}
		}
		.panel-head {
			flex: 1 0 auto;
			margin-left: -0.5rem;
			margin-right: -0.5rem;

			> * {
				flex: 1 0 50%;
				padding-left: 0.5rem;
				padding-right: 0.5rem;
			}
			.focus-time {
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;
			}
			p {
				text-transform: lowercase;
			}
			i {
				color: var(--b400);
				font-size: 0.5625rem;
				margin-right: 0.125rem;
				margin-top: 0.25rem;
			}
		}
		.panel-content {
			padding-top: 1rem;
		}
	}
	.time-details {
		padding: 0;
		line-height: 0;
		list-style: none;
		display: flex;
		margin: 1.5rem -0.5rem 0 -0.5rem;
		justify-content: space-between;

		li {
			padding: 0 0.5rem;
			font-size: 0.6875rem;
			line-height: 0.9625rem;
			color: var(--n200);

			h6 {
				margin-bottom: 0.25rem;
				font-size: 0.8125rem;
				font-weight: 400;

				span {
					display: inline-block;
					width: 0.25rem;
					height: 0.25rem;
					border-radius: 50%;
					vertical-align: top;
					margin-top: 0.375rem;
				}
			}
		}
	}
	.weekly-insight {
		margin-right: 1.25rem;

		&.panel-open {
			.panel-head {
				.hidden-collapsed {
					display: block;
					color: var(--n500);
					font-size: 0.8125rem;
					line-height: 1.125rem;
					margin-bottom: 0.75rem;
				}
				h4 {
					font-size: 1.25rem;
					line-height: 1.25rem;
					margin-bottom: 0.5rem;
				}
				p {
					font-size: 0.875rem;
					margin: 0;
				}
				.wrapper {
					p {
						display: block;

						&.show-collapsed {
							display: none;
						}
					}
          .view-analytics {
            font-size: 0.8125rem;
          }
				}
				.working-days {
					ul {
						li {
							span {
								width: 1.5rem;
								height: 1.5rem;
								line-height: 1.375rem;
								font-size: 0.75rem;

								&.tracked {
									font-size: 0.5rem;
								}
							}
						}
					}
				}
			}
		}
		.panel-head {
			margin-left: -0.5rem;
			margin-right: -0.5rem;
			flex-wrap: wrap;

			> * {
				flex: 1 0 50%;
				padding-left: 0.5rem;
				padding-right: 0.5rem;
				max-width: 50%;
			}
			.hidden-collapsed {
				display: none;
				flex: 1 0 100%;
				max-width: 100%;
			}
			.wrapper {
				h4 {
					color: var(--n600);
				}
				p {
					display: none;

					&.show-collapsed {
						display: block;
					}
				}
        .view-analytics {
          font-size: .875rem;
          cursor: pointer;
          display: inline-block;
          margin-top: -0.125rem;
        }
			}
			.working-days {
				ul {
					display: flex;
					margin: 0 -0.0625rem 0.125rem -0.0625rem;
					list-style: none;
          flex-wrap:wrap;

          @media (min-width: 75rem ) {
            flex-wrap: nowrap;
          }
					li {
            font-size:0;
            line-height:0;

						span {
              margin: 0 .125rem 0.125rem 0;
							display: block;
							text-align: center;
							width: 1rem;
							height: 1rem;
							font-size: 0.5rem;
							font-weight: bold;
							line-height: 0.875rem;
							border: 0.0625rem solid var(--n60);
							color: var(--n60);
							border-radius: 50%;

							&.tracked {
								border-color: var(--g300);
								background-color: var(--g300);
								color: var(--n0);
								font-size: 0.375rem;

								i {
									display: inline-block;

									&:before {
										width: auto;
										margin-right: 0.0625rem;
									}
								}
                &.offday {
                  background: var(--n40);
                  border-color: var(--n60);
                }
							}
						}
					}
				}
			}
		}
		.panel-content {
			padding-top: 1rem;

			ul {
				margin: 0;
				padding: 0;
				list-style: none;

				li {
					font-size: .75rem;
					line-height: .75rem;
					color: var(--n200);

					strong {
						display: block;
						font-weight: 400;
						color: var(--n500);
						margin-bottom: 0.25rem;
						font-size: 0.8125rem;
						line-height: 0.8125rem;
					}
					+ li {
						margin-top: 0.75rem;
					}
				}
			}
		}
	}
}
.calendar-block {
	width: 1rem;
	height: 1rem;
}
